class MCollectionsCategoryThumb {
  constructor(element) {
    const marqueeElement = element.querySelector('.m-objects-marquee');
    const liElements = element.querySelectorAll('.m-collections-category-thumb__title a');

    function onMouseenter() {
      marqueeElement.classList.add('-link-focus');
    }
    function onMouseleave() {
      marqueeElement.classList.remove('-link-focus');
    }

    liElements.forEach((liElement) => {
      liElement.addEventListener('mouseenter', onMouseenter);
      liElement.addEventListener('mouseleave', onMouseleave);
    });
  }
}

document.querySelectorAll('.m-collections-category-thumb').forEach((element) => new MCollectionsCategoryThumb(element));
