class Element {
  constructor(element, rootMargin = '0px') {
    this.element = element;
    this.relativeCenterY = 1;
    this.gap = 60;
    this.em = (px) => `${px / 16}em`;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        this.onIntersect.bind(this)(entry.isIntersecting);
      });
    }, { threshold: [0, 1], rootMargin });
    observer.observe(element);

    window.addEventListener('resize', this.onResize.bind(this), {
      passive: true,
    });
    document.addEventListener('scroll', this.onScroll.bind(this), {
      passive: true,
    });

    this.setOffsets();
    this.onScroll();
    window.addEventListener('load', () => {
      this.setOffsets();
      this.onScroll();
    });
  }

  onIntersect(isIntersecting = true) {
    const {
      element,
    } = this;
    this.isIntersecting = isIntersecting;
    element.setAttribute('data-will-be-in-view', isIntersecting);
    if (isIntersecting) {
      this.onScroll();
    }
  }

  setOffsets() {
    this.offsetTop = this.element.offsetTop;
    this.offsetHeight = this.element.offsetHeight;
  }

  onResize() {
    this.setOffsets();
    this.onScroll();
  }

  onScroll() {
    if (this.isIntersecting) {
      let top = 0;
      let height = 0;

      const getDimensions = () => {
        top = this.offsetTop - document.scrollingElement.scrollTop;
        height = this.offsetHeight;
      };

      getDimensions();
      const {
        innerHeight,
      } = window;
      const elementCenterY = top + height * 0.5;
      const viewportCenterY = innerHeight * 0.5;

      this.top = top; // 0 when appears from bottom
      this.bottom = top + height; // 0 when disappears to top
      // 0 when element is in center of viewport
      // > 1 when disappears top
      // > -1 when disappears bottom
      this.relativeCenterY = ((elementCenterY - viewportCenterY)
        / (height * 0.5 + innerHeight * 0.5));
    }
  }
}

export default Element;
