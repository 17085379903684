import Element from './Element';

class OStartProgram extends Element {
  constructor(element) {
    super(element);
    this.imgElement = element.querySelector('.o-start-program__img');
    this.textElement = element.querySelector('.o-start-program__text');
  }

  onScroll() {
    super.onScroll();
    const {
      bottom,
      imgElement,
      textElement,
    } = this;
    const {
      innerHeight,
    } = window;

    const base = bottom - innerHeight;

    if (imgElement && base < 0) {
      const opacity = 1 - base * -0.0008;
      const transalateTextY = base * 0.2;

      Object.assign(imgElement.style, {
        opacity,
      });
      Object.assign(textElement.style, {
        transform: `translateY(${transalateTextY}px)`,
      });
    }
  }
}

document.querySelectorAll('.o-start-program').forEach((element) => new OStartProgram(element));
