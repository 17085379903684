import Easings from './Easings';

class MHighlightedExhibitionFigure {
  constructor(element, mHighlightedExhibition) {
    this.element = element;
    this.mHighlightedExhibition = mHighlightedExhibition;
    this.divElement = element.querySelector('.m-highlighted-exhibition__figure > div');
    this.offsetTopMax = 80;
    this.isIntersecting = false;
  }

  onIntersect(isIntersecting = true) {
    const {
      divElement,
    } = this;
    const {
      em,
      gap,
    } = this.mHighlightedExhibition;
    this.isIntersecting = isIntersecting;
    if (divElement) {
      if (window.matchMedia(`(min-width: ${em(gap * 18)})`).matches && isIntersecting) {
        divElement.style.willChange = 'transform';
      } else {
        divElement.style.willChange = null;
      }
    }
  }

  onScroll() {
    const {
      isIntersecting,
      divElement,
      offsetTopMax,
      mHighlightedExhibition,
    } = this;
    if (isIntersecting && divElement && mHighlightedExhibition) {
      const { relativeCenterY } = mHighlightedExhibition;
      const easedRelativeCenterY = Easings.easeInQuad(Math.abs(relativeCenterY));
      const translateY = (relativeCenterY > 0
        ? (easedRelativeCenterY + relativeCenterY * 0.5) * offsetTopMax
        : (easedRelativeCenterY - relativeCenterY * 0.5) * offsetTopMax * -1);
      divElement.style.transform = `translateY(${translateY}px)`;
    }
  }

  reset() {
    const {
      divElement,
    } = this;
    if (divElement) {
      divElement.style.transform = 'none';
      divElement.style.willChange = null;
    }
  }
}

export default MHighlightedExhibitionFigure;
