class OObject {
  constructor(element) {
    const nextElement = element.querySelector('.o-object__prev-next .a-button-text.-next');
    const backElement = element.querySelector('.o-object__prev-next .a-button-text.-back');
    const imagesElement = element.querySelector('.o-object__images');
    const imgElements = imagesElement?.querySelectorAll('img');

    const updateImgElements = () => {
      const imagesWidth = imagesElement.offsetWidth;
      imgElements?.forEach((imgElement) => {
        if (imgElement.closest('.m-figure').offsetWidth === imagesWidth) {
          imgElement.classList.add('-no-zoom');
        } else {
          imgElement.classList.remove('-no-zoom');
        }
      });
    };

    imgElements?.forEach((imgElement) => {
      imgElement.addEventListener('click', () => {
        imgElement.classList.toggle('-zoomed');
      });
    });
    document.addEventListener('keydown', (event) => {
      if (!event.metaKey && !event.ctrlKey && !event.altKey) {
        if (event.code === 'ArrowLeft' && backElement) {
          backElement.click();
        } else if (event.code === 'ArrowRight' && nextElement) {
          nextElement.click();
        }
      }
    });
    window.addEventListener('resize', updateImgElements, {
      passive: true,
    });

    updateImgElements();
  }
}

document.querySelectorAll('.o-object').forEach((element) => new OObject(element));
