import MObjectsMarqueeRow from './m-objects-marquee__row';
import Element from './Element';

class MObjectsMarquee extends Element {
  constructor(element) {
    super(element);
    this.animationPaused = true;
    this.animationPauseByButton = false;
    this.buttonToggleAnimation = element.querySelector('button[data-action="toggle-animation"]');
    this.rows = Array.from(element.querySelectorAll('.m-objects-marquee__row')).map((rowElement, index) => new MObjectsMarqueeRow(rowElement, index));

    const liElements = element.querySelectorAll('a');

    function onLiElementMouseenter() {
      element.classList.add('-link-focus');
    }
    function onLiElementMouseleave() {
      element.classList.remove('-link-focus');
    }

    this.buttonToggleAnimation.addEventListener('click', () => {
      if (this.animationPaused !== false) {
        this.play();
        this.animationPauseByButton = false;
        document.mObjectsMarquees.forEach((mObjectsMarquee) => {
          if (mObjectsMarquee !== this) {
            mObjectsMarquee.play();
            Object.assign(mObjectsMarquee, {
              animationPauseByButton: false,
            });
          }
        });
      } else {
        this.pause();
        this.animationPauseByButton = true;
        document.mObjectsMarquees.forEach((mObjectsMarquee) => {
          if (mObjectsMarquee !== this) {
            mObjectsMarquee.pause();
            Object.assign(mObjectsMarquee, {
              animationPauseByButton: true,
            });
          }
        });
      }
    });

    if (element.classList.contains('-wild')) {
      liElements.forEach((liElement) => {
        liElement.addEventListener('mouseenter', onLiElementMouseenter);
        liElement.addEventListener('mouseleave', onLiElementMouseleave);
      });
    }
  }

  play() {
    const {
      buttonToggleAnimation,
    } = this;
    if (this.animationPaused !== false) {
      this.rows.forEach((row) => row.play());
      const { title } = buttonToggleAnimation;
      const { titleToggle } = buttonToggleAnimation.dataset;
      buttonToggleAnimation.setAttribute('title', titleToggle);
      buttonToggleAnimation.setAttribute('data-title-toggle', title);
      buttonToggleAnimation.classList.remove('-paused');
      this.animationPaused = false;
    }
  }

  pause() {
    const {
      buttonToggleAnimation,
    } = this;
    if (this.animationPaused !== true) {
      this.rows.forEach((row) => row.pause());
      const { title } = buttonToggleAnimation;
      const { titleToggle } = buttonToggleAnimation.dataset;
      buttonToggleAnimation.setAttribute('title', titleToggle);
      buttonToggleAnimation.setAttribute('data-title-toggle', title);
      buttonToggleAnimation.classList.add('-paused');
      this.animationPaused = true;
    }
  }

  onIntersect(isIntersecting = true) {
    const {
      animationPauseByButton,
    } = this;
    super.onIntersect(isIntersecting);
    if (isIntersecting && animationPauseByButton !== true) {
      this.play();
    } else {
      this.pause();
    }
  }
}

// no Internet Explorer
if (!window.document.documentMode) {
  document.mObjectsMarquees = [];
  document.querySelectorAll('.m-objects-marquee').forEach((element) => {
    document.mObjectsMarquees.push(new MObjectsMarquee(element));
  });
}
