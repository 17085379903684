class MCalendarFreeEntry {
  constructor(element) {
    this.element = element;
    this.id = element.id;
    this.startDate = new Date(element.dataset.startDate);
    this.month = element.dataset.month;

    Object.assign(element, {
      mCalendarFreeEntry: this,
    });

    if (document.location.hash.substr(1) === this.id) {
      const {
        offsetHeight,
        offsetTop,
      } = this.element;
      const {
        innerHeight,
      } = window;
      if (document.scrollingElement.scrollTo) {
        const marginTop = 70;
        const top = offsetHeight > innerHeight
          ? offsetTop - marginTop
          : offsetTop + offsetHeight * 0.5 - innerHeight * 0.5;
        document.scrollingElement.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }

    this.updateElement();
  }

  updateElement() {
    const {
      element,
    } = this;

    function getPreviousVisibleElementSibling() {
      let siblingElement = element.previousElementSibling;
      while (siblingElement) {
        if (!siblingElement.hidden) return siblingElement;
        siblingElement = siblingElement.previousElementSibling;
      }
      return null;
    }

    const previousVisibleElementSibling = getPreviousVisibleElementSibling();

    // reset
    element.classList.remove('-first-day');

    if (!previousVisibleElementSibling) {
      element.classList.add('-first-day');
    }
  }

  hide() {
    const {
      element,
    } = this;

    element.hidden = true;
  }

  show() {
    const {
      element,
    } = this;

    element.hidden = false;
    this.updateElement();
  }
}

export default MCalendarFreeEntry;
