import './custom-elements/details-menu-element';
import './components/HelperGrid';
import './components/a-audio';
import './components/m-collections-category-thumb';
import './components/m-expandable-text';
import './components/m-exhibition-header';
import './components/m-form';
import './components/m-figure';
import './components/m-form-newsletter';
import './components/m-objects-marquee';
import './components/m-site-search';
import './components/m-start-highlight';
import './components/m-video';
import './components/m-highlighted-exhibition';
import './components/o-collections-collection';
import './components/o-collections-nav';
import './components/o-collections-thumbs';
import './components/o-digital-category';
import './components/o-education-header';
import './components/o-main-nav';
import './components/o-calendar';
import './components/o-modal';
import './components/o-notice';
import './components/o-object';
import './components/o-house';
import './components/o-start-program';

if (window.document.documentMode) {
  document.querySelector('html').classList.add('-ie');
}
