import Element from './Element';
import Easings from './Easings';

class MExhibitionHeader extends Element {
  constructor(element) {
    super(element);
    this.element = element;
    this.metaElement = element.querySelector('.m-exhibition-header__meta');
    this.centerElement = element.querySelector('.m-exhibition-header__center');
    this.h1Element = element.querySelector('.m-exhibition-header__center h1');
    this.pElement = element.querySelector('.m-exhibition-header__center p');
    this.figureElement = element.querySelector('figure');
    this.imgElement = element.querySelector('figure img');

    setTimeout(() => {
      element.classList.remove('-stage-animation');
      setTimeout(() => {
        element.classList.add('-start-animation-ended');
      }, 200 * 4);
    }, 60);

    document.addEventListener('scroll', () => {
      element.classList.add('-start-animation-ended');
    });
  }

  onScroll() {
    super.onScroll();
    const {
      top,
      offsetHeight,
      centerElement,
      metaElement,
      h1Element,
      pElement,
      figureElement,
      imgElement,
      isIntersecting,
    } = this;

    if (isIntersecting
      && metaElement
      && h1Element
      && pElement
      && figureElement
      && imgElement
      && top
    ) {
      let relativeYWindow = (top / window.innerHeight) * -1;
      relativeYWindow = Math.max(Math.min(relativeYWindow, 1), 0);
      relativeYWindow = Easings.linear(relativeYWindow);
      let relativeY = (top / offsetHeight) * -1;
      relativeY = Math.max(Math.min(relativeY, 1), 0);
      metaElement.style.opacity = 1 - 1 * relativeYWindow;
      metaElement.style.transform = `scale(${1 - 0.1 * relativeYWindow})`;
      metaElement.style.transform = `translateY(${relativeYWindow * -100}px)`;
      h1Element.style.transform = `translateY(${relativeYWindow * -150}px)`;
      pElement.style.transform = `translateY(${relativeYWindow * -140}px)`;
      centerElement.style.opacity = 1 - 0.5 * relativeYWindow;
      centerElement.style.transform = `scale(${1 - 0.05 * relativeYWindow})`;

      const relativeYFigure = Easings.easeInQuad(relativeY);
      figureElement.style.transform = `translateY(${relativeYFigure * -150}px)`;
    }
  }
}

document.querySelectorAll('.m-exhibition-header').forEach((element) => new MExhibitionHeader(element));

// export default MExhibitionHeader;
