class OCollectionsCollection {
  constructor(element) {
    const buttonReadOnElement = element.querySelector('button[data-action="expand"]');
    const buttonCloseElement = element.querySelector('button[data-action="shrink"]');
    const textMoreElement = element.querySelector('.o-collections-collection__text-more');

    buttonReadOnElement.addEventListener('click', () => {
      textMoreElement.hidden = false;
      buttonReadOnElement.hidden = true;
      textMoreElement.focus();
    });

    buttonCloseElement.addEventListener('click', () => {
      textMoreElement.hidden = true;
      buttonReadOnElement.hidden = false;
      buttonReadOnElement.focus();
    });
  }
}

document.querySelectorAll('.o-collections-collection').forEach((element) => new OCollectionsCollection(element));
