import Easings from './Easings';

class ONotice {
  constructor(element) {
    const { hash } = element.dataset;
    this.sessionId = `o-notice-${hash}`;
    const linkElements = element.querySelectorAll('a[href]');
    this.buttonCloseElement = element.querySelector('[data-action="close"]');
    this.element = element;
    this.mBlocksElements = element.querySelectorAll('.m-blocks > *');
    this.sessionClosed = localStorage.getItem(`${this.sessionId}-closed`);
    if (this.sessionClosed === null) {
      this.sessionClosed = 'false';
      localStorage.setItem(`${this.sessionId}-closed`, 'false');
    }

    this.buttonCloseElement.addEventListener('click', () => {
      localStorage.setItem(`${this.sessionId}-closed`, 'true');
      this.close();
    });
    linkElements.forEach((linkElement) => {
      linkElement.addEventListener('click', () => {
        localStorage.setItem(`${this.sessionId}-closed`, 'true');
      });
    });

    if (this.sessionClosed === 'false') {
      this.open();
    }
  }

  open() {
    this.element.removeAttribute('hidden');
    this.element.animate([
      {
        transform: 'translateY(100%)',
      }, {
        transform: '',
      },
    ], {
      duration: 600,
      easing: 'cubic-bezier(0, 0.4, 0.25, 1)',
    });
    this.mBlocksElements.forEach((element, i) => {
      element.animate([
        {
          transform: 'translateY(2rem)',
        }, {
          transform: '',
        },
      ], {
        fill: 'backwards',
        duration: 600 - 200 + 200 * (i + 1),
        easing: 'cubic-bezier(0, 0.4, 0.25, 1)',
      });
    });
    this.buttonCloseElement.animate([
      {
        transform: 'scale(0.98)',
        opacity: '0',
      },
      {
        transform: 'none',
        opacity: '1',
      },
    ], {
      fill: 'backwards',
      delay: 400,
      duration: 600,
      easing: 'cubic-bezier(0, 0.4, 0.25, 1)',
    });
  }

  close() {
    const currentAnimation = this.element.animate([
      {
        transform: '',
      }, {
        transform: 'translateY(100%)',
      },
    ], {
      fill: 'forwards',
      duration: 400,
      easing: Easings.easing(),
    });
    currentAnimation.addEventListener('finish', () => {
      this.element.setAttribute('hidden', '');
    });
  }
}

document.querySelectorAll('.o-notice').forEach((element) => new ONotice(element));
