class MStartHighlight {
  constructor(element, index) {
    this.element = element;
    const gap = 60;
    const em = (px) => `${px / 16}em`;
    this.textElement = element.querySelector('.m-start-highlight__text');
    this.imgElement = element.querySelector('.m-start-highlight__figure img');

    const updateMarginTopByImage = () => {
      if (window.matchMedia(`(min-width: ${em(gap * 10)})`).matches) {
        Object.assign(element.style, {
          marginTop: `${this.imgElement.offsetHeight * -1}px`,
        });
      } else {
        Object.assign(element.style, {
          marginTop: null,
        });
      }
    };

    const updateMarginTopByPreviousText = () => {
      if (window.matchMedia(`(min-width: ${em(gap * 10)})`).matches) {
        const marginTop = window.mStartHighlights[index - 1].textElement.offsetHeight;
        Object.assign(element.style, {
          marginTop: `${marginTop}px`,
        });
      } else {
        Object.assign(element.style, {
          marginTop: null,
        });
      }
    };

    if (window.CSS && window.CSS.supports('display', 'grid')) {
      if (index === 1 || index === 3) {
        updateMarginTopByImage();
        this.imgElement.addEventListener('load', updateMarginTopByImage);
        window.addEventListener('resize', updateMarginTopByImage);
        window.addEventListener('orientationchange', updateMarginTopByImage);
      }
      if (index === 2 || index === 4) {
        updateMarginTopByPreviousText();
        window.addEventListener('load', updateMarginTopByPreviousText);
        window.addEventListener('resize', updateMarginTopByPreviousText);
        window.addEventListener('orientationchange', updateMarginTopByPreviousText);
      }
    }
  }
}

window.mStartHighlights = [];
document.querySelectorAll('.m-start-highlight').forEach((element, index) => {
  window.mStartHighlights.push(new MStartHighlight(element, index));
});
