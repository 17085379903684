class MFigure {
  constructor(element) {
    /* eslint-disable no-use-before-define */
    const buttonElement = element.querySelector('.a-button-icon');
    const captionElement = element.querySelector('.m-figure__caption');

    const show = () => {
      Object.assign(captionElement, {
        hidden: false,
      });
      element.classList.add('-caption-is-open');
      requestAnimationFrame(() => {
        document.addEventListener('click', onDocumentClick);
        requestAnimationFrame(() => {
          if (captionElement.hidden === false) {
            captionElement.classList.remove('-hidden');
          }
        });
      });
    };

    const hide = () => {
      captionElement?.classList.add('-hidden');
      document.removeEventListener('click', onDocumentClick);
      element.classList.remove('-caption-is-open');
    };

    const toggle = () => {
      if (captionElement?.hidden === true) {
        show();
      } else {
        hide();
      }
    };

    const onDocumentClick = (event) => {
      if (event.target !== element && event.target.closest('.m-figure, .m-text__figure') !== element) {
        hide();
      }
    };

    if (captionElement) {
      captionElement.addEventListener('transitionend', () => {
        if (captionElement.classList.contains('-hidden')) {
          captionElement.hidden = true;
        }
      });
    }
    if (buttonElement) {
      buttonElement.addEventListener('click', toggle);
    }
  }
}

document.querySelectorAll('.m-figure, .m-text__figure').forEach((element) => new MFigure(element));
