import Element from './Element';
import Easings from './Easings';

class OEducationHeader extends Element {
  constructor(element) {
    super(element);
    this.imgElement = element.querySelector('.o-education-header__figure img');
    this.translateYMax = -60 * 3; // bottom of .o-education-header figure
    this.translateYImgMax = -60; // bottom of .o-education-header figure
  }

  onIntersect(isIntersecting = true) {
    super.onIntersect(isIntersecting);
    if (isIntersecting) {
      this.imgElement.style.willChange = 'transform';
    } else {
      this.imgElement.style.willChange = null;
    }
  }

  onScroll() {
    if (this.isIntersecting) {
      const { offsetHeight, translateYMax, translateYImgMax } = this;
      const { scrollTop } = document.scrollingElement;
      const relative = (scrollTop / offsetHeight);
      let relativeEased = relative > 1 ? 1 : relative;
      relativeEased = Easings.easeInQuad(relative);
      this.element.style.transform = `translateY(${relativeEased * translateYMax}px)`;
      this.imgElement.style.transform = `translateY(${relative * translateYImgMax}px)`;
    }
  }
}

document.querySelectorAll('.o-education-header').forEach((element) => new OEducationHeader(element));
