class HelperGrid {
  constructor() {
    const rows = Math.floor(window.innerHeight / 40);
    const columns = 20;
    const columnGap = 60;
    const width = columnGap * columns * 2;

    const elementStyle = {
      display: 'grid',
      justifyContent: 'center',
      position: 'fixed',
      zIndex: 100,
      pointerEvents: 'none',
      gridAutoRows: '20px',
      gridAutoColumns: '60px',
      columnGap: '60px',
      rowGap: '20px',
      alignContent: 'start',
      paddingLeft: `${columnGap}px`,
      overflow: 'hidden',
      left: `calc(50% - ${width * 0.5}px)`,
      right: 0,
      top: 0,
      bottom: 0,
      width: `${width - columnGap}px`,
      // opacity: 0.1,
      // backgroundColor: 'hsla(0, 0%, 0% , 1)',
    };
    const rowColumnElementStyle = {
      backgroundColor: 'hsla(0, 0%, 0% , 0.05)',
    };

    this.element = document.createElement('div');
    this.element.setAttribute('id', 'helper-grid');
    Object.assign(this.element.style, elementStyle);

    // columns
    for (let i = 0; i <= columns; i += 1) {
      const childElement = document.createElement('div');
      const columnElementStyle = { ...rowColumnElementStyle };
      columnElementStyle.gridColumnStart = i + 1;
      columnElementStyle.gridRowStart = 1;
      columnElementStyle.gridRowEnd = rows + 2;
      Object.assign(childElement.style, columnElementStyle);
      this.element.appendChild(childElement);
    }

    // rows
    for (let i = 0; i <= rows; i += 1) {
      const childElement = document.createElement('div');
      const rowElementStyle = { ...rowColumnElementStyle };
      rowElementStyle.gridRowStart = i + 1;
      rowElementStyle.gridColumnEnd = columns + 2;
      rowElementStyle.gridColumnStart = 1;
      Object.assign(childElement.style, rowElementStyle);
      this.element.appendChild(childElement);
    }

    document.addEventListener('keydown', (event) => {
      if (event.ctrlKey && event.key === 'l') {
        if (document.body.children.namedItem('helper-grid') === this.element) {
          this.hide();
        } else {
          this.show();
        }
      }
    });
  }

  show() {
    document.body.appendChild(this.element);
  }

  hide() {
    document.body.removeChild(this.element);
  }
}

new HelperGrid();
