class MForm {
  constructor(element) {
    this.element = element;
    this.action = element.action;
    this.method = element.method;
    this.formElements = element.elements;
    this.errorElements = element.querySelectorAll('.a-label__error');
    this.globaleErrorElement = element.querySelector('.m-form__error');

    this.buttonElement = element.querySelector('button');

    element.addEventListener('submit', async (event) => {
      event.preventDefault();
      this.buttonElement.classList.add('-loading');
      this.clearErros();
      const result = await this.submit().catch((error) => {
        this.showErrors(error);
      });
      this.buttonElement.classList.remove('-loading');
      const success = result.success ?? false;
      if (success !== false) {
        this.success(result.success);
      } else {
        this.showErrors(result.alert);
      }
    });

    Array.from(this.formElements).forEach((formElement) => {
      formElement.dataset.defaultValue = formElement.value;
    });
  }

  clearErros() {
    this.errorElements.forEach((errorElement) => {
      Object.assign(errorElement, {
        innerText: null,
      });
    });
    if (this.globaleErrorElement) {
      Object.assign(this.globaleErrorElement, {
        innerText: null,
      });
    }
  }

  showErrors(errors = { global: 'Fatal Error' }) {
    const {
      formElements,
      globaleErrorElement,
    } = this;
    let isFocus = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(errors)) {
      const formElement = formElements.namedItem(key);
      const value = errors[key];
      const errorElement = formElement?.closest('label')?.querySelector('.a-label__error');
      if (formElement && errorElement) {
        errorElement.innerHTML = value;
        if (isFocus === false) {
          formElement.focus();
          isFocus = true;
        }
      } else {
        globaleErrorElement.innerHTML += `<p>${value}</p>`;
      }
    }
  }

  enableForm() {
    const {
      formElements,
    } = this;

    Array.from(formElements).forEach((formElement) => {
      Object.assign(formElement, {
        disabled: false,
      });
    });
  }

  disableForm() {
    const {
      formElements,
    } = this;

    Array.from(formElements).forEach((formElement) => {
      Object.assign(formElement, {
        disabled: true,
      });
    });
  }

  submit() {
    const {
      action,
      element,
      method,
    } = this;
    const formData = new FormData(element);
    this.disableForm();
    return fetch(action, {
      method,
      body: formData,
    }).then((response) => response.json())
      .then((result) => {
        if (result.success === false) {
          this.enableForm();
        }
        return result;
      })
      .catch((error) => {
        this.enableForm();
        return error;
      });
  }

  success(message = 'Success') {
    const {
      element,
      buttonElement,
    } = this;
    const successElement = element.querySelector('.m-form__success');
    successElement.hidden = false;
    buttonElement.hidden = true;
    successElement.querySelector('span').innerHTML = message;
    element.classList.add('-success');
  }

  resetSuccess() {
    const {
      element,
      buttonElement,
      formElements,
    } = this;
    this.enableForm();
    const successElement = element.querySelector('.m-form__success');
    successElement.hidden = true;
    buttonElement.hidden = false;
    Array.from(formElements).forEach((formElement) => {
      if (formElement.type !== 'hidden') {
        Object.assign(formElement, {
          value: formElement.dataset.defaultValue,
        });
      }
    });
    successElement.querySelector('span').innerHTML = '';
    element.classList.remove('-success');
  }
}

// eslint-disable-next-line no-underscore-dangle
window._forms = [];
document.querySelectorAll('.m-form[action]').forEach((element) => {
  // eslint-disable-next-line no-underscore-dangle
  window._forms.push(new MForm(element));
});
