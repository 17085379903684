class AAudio {
  constructor(element) {
    this.element = element;
    this.audioElement = element.querySelector('audio');
    this.playNext = true;
    this.buttonPlayElement = element.querySelector('button[data-action="play"]');
    const thumbElement = element.querySelector('.a-audio__thumb');
    const inputRangeElement = element.querySelector('.a-audio__range input[type="range"]');
    const progressTrackElement = element.querySelector('.a-audio__progress-track');
    const timeCurrentElement = element.querySelector('.a-audio__time-current');
    const timeDurationElement = element.querySelector('.a-audio__time-duration');

    const labelPlay = this.buttonPlayElement.getAttribute('aria-label');
    const labelPause = this.buttonPlayElement.getAttribute('data-label-pause');

    const pauseOthers = () => {
      window.aAudios.forEach((aAudio) => {
        if (!aAudio.audioElement.paused && aAudio !== this) {
          aAudio.audioElement.pause();
        }
      });
    };
    const playNext = () => {
      if (this.playNext === true) {
        const currentIndex = window.aAudios.findIndex((aAudio) => aAudio === this);
        const nextAAudio = window.aAudios[currentIndex + 1];
        if (nextAAudio) {
          nextAAudio.audioElement.play();
          nextAAudio.element.focus();
        }
      }
    };
    const convertTime = (time) => {
      if (typeof time === 'number') {
        const timeRounded = Math.round(time);
        // Minutes and seconds
        let mins = Math.floor((timeRounded / 60));
        let secs = timeRounded % 60;
        // Hours, minutes and seconds
        const hrs = Math.floor((timeRounded / 3600));
        mins = Math.floor(((timeRounded % 3600) / 60));
        secs = timeRounded % 60;
        // Output like "1:01" or "4:03:59" or "123:03:59"
        let string = '';
        if (hrs > 0) string += `${hrs}:`;

        string += `${mins < 10 ? '0' : ''}`;
        string += `${mins}:${secs < 10 ? '0' : ''}`;
        string += `${secs}`;
        return string;
      }
      return '00:00';
    };

    this.element.addEventListener('mouseenter', () => {
      if (Number.isNaN(this.audioElement.duration)) {
        this.audioElement.load();
      }
    });

    this.buttonPlayElement.addEventListener('click', () => {
      if (this.audioElement.paused) {
        this.audioElement.play();
      } else {
        this.audioElement.pause();
      }
    });

    this.audioElement.addEventListener('loadedmetadata', () => {
      timeDurationElement.innerText = convertTime(this.audioElement.duration);
    });
    this.audioElement.addEventListener('play', () => {
      pauseOthers();
      this.element.classList.add('-playing');
      this.buttonPlayElement.setAttribute('aria-label', labelPause);
      this.trackEvent('Play');
    });
    this.audioElement.addEventListener('pause', () => {
      this.element.classList.remove('-playing');
      this.buttonPlayElement.setAttribute('aria-label', labelPlay);
      this.trackEvent('Pause');
    });
    this.audioElement.addEventListener('ended', () => {
      this.audioElement.currentTime = 0;
      playNext();
      this.trackEvent('Ended');
    });
    this.audioElement.addEventListener('timeupdate', () => {
      const relativeDuration = this.audioElement.currentTime / this.audioElement.duration;
      inputRangeElement.value = relativeDuration;
      thumbElement.style.left = `${relativeDuration * 100}%`;
      progressTrackElement.style.transform = `scaleX(${relativeDuration})`;
      timeCurrentElement.innerText = convertTime(this.audioElement.currentTime);
      if (timeDurationElement.innerText === '00:00') {
        timeDurationElement.innerText = convertTime(this.audioElement.duration);
      }
    });

    inputRangeElement.addEventListener('click', () => {
      if (this.audioElement.paused) {
        this.audioElement.play();
      }
    });
    inputRangeElement.addEventListener('change', (event) => {
      const { value } = event.target;
      this.audioElement.currentTime = this.audioElement.duration * parseFloat(value);
    });
    inputRangeElement.addEventListener('input', (event) => {
      const { value } = event.target;
      this.audioElement.currentTime = this.audioElement.duration * parseFloat(value);
    });
  }

  trackEvent(event) {
    // eslint-disable-next-line no-underscore-dangle
    window._paq?.push(['trackEvent', 'Audio', event, this.audioElement.currentSrc]);
  }
}

window.aAudios = Array.from(document.querySelectorAll('.a-audio')).map((element) => new AAudio(element));
