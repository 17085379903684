class MObjectsMarqueeRow {
  constructor(element, index) {
    const speed = index === 0 ? -0.7 : -0.8;
    const speedSlow = -0.4;
    this.speed = speed;
    this.speedSlow = speedSlow;
    const ulElement = element.querySelector('ul');

    this.element = element;
    this.currentSpeed = speedSlow;
    this.destinationSpeed = this.speed;
    this.translateX = 0;
    this.isPaused = false;
    this.nextTick = false;

    const shuffle = (elements) => {
      const allElems = (function () {
        const ret = []; let
          l = elements.length;
        while (l--) { ret[ret.length] = elements[l]; }
        return ret;
      }());

      const shuffledElements = (() => {
        let l = allElems.length;
        const _shuffledElements = [];
        while (l--) {
          const random = Math.floor(Math.random() * allElems.length); const
            randEl = allElems[random].cloneNode(true);
          allElems.splice(random, 1);
          _shuffledElements[_shuffledElements.length] = randEl;
        }
        return _shuffledElements;
      })();
      let l = elements.length;

      while (l--) {
        elements[l].parentNode.insertBefore(shuffledElements[l], elements[l].nextSibling);
        elements[l].parentNode.removeChild(elements[l]);
      }
    };

    const init = () => {
      shuffle(ulElement.children);

      this.ulCloneElement = ulElement.cloneNode(true);
      this.ulCloneElement.setAttribute('aria-hidden', 'true');
      element.appendChild(this.ulCloneElement);

      updateWidth();

      this.liElements = element.querySelectorAll('li');
      this.liElements.forEach((liElement) => {
        observer.observe(liElement);
      });

      updateSpeed();
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const aElement = entry.target.querySelector('a');
        if (aElement) {
          if (entry.isIntersecting) {
            aElement.removeAttribute('tabindex');
          } else {
            aElement.setAttribute('tabindex', '-1');
          }
        }
      });
    }, { threshold: [0, 1], rootMargin: '0px' });

    const updateWidth = () => {
      this.ulWidth = ulElement.offsetWidth;
    };
    const updateSpeed = () => {
      if (window.matchMedia(`(max-width: ${(480 / 0.72 - 1) / 16}em)`).matches) {
        const relative = (window.innerWidth / (480 / 0.72));
        this.speed = speed * relative;
        this.speedSlow = speedSlow * relative;
      } else {
        this.speed = speed;
        this.speedSlow = speedSlow;
      }
      this.destinationSpeed = this.speed;
    };

    const onResize = () => {
      updateSpeed();
    };

    element.addEventListener('mouseenter', () => {
      this.destinationSpeed = this.speedSlow;
    });
    element.addEventListener('mouseleave', () => {
      this.destinationSpeed = this.speed;
    });
    document.addEventListener('load', updateWidth);
    window.addEventListener('resize', onResize, {
      passive: true,
    });

    init();
  }

  tick() {
    const {
      currentSpeed,
      element,
      liElements,
      translateX,
      isPaused,
    } = this;
    let {
      destinationSpeed,
    } = this;

    let newSpeed;

    if (isPaused === true) {
      destinationSpeed = 0;
    }

    // fade
    if (Math.round(currentSpeed * 100) !== Math.round(destinationSpeed * 100)) {
      if (currentSpeed < destinationSpeed) {
        newSpeed = currentSpeed + 0.03;
        if (newSpeed > destinationSpeed) {
          newSpeed = destinationSpeed;
        }
      } else {
        newSpeed = currentSpeed - 0.03;
        if (newSpeed < destinationSpeed) {
          newSpeed = destinationSpeed;
        }
      }
    } else {
      newSpeed = destinationSpeed;
    }

    let newTranslateX = translateX + newSpeed;
    if (newTranslateX * -1 >= this.ulWidth) {
      newTranslateX = 0;
    }
    liElements.forEach((liElement) => {
      Object.assign(liElement.style, {
        transform: `translateX(${newTranslateX}px)`,
      });
    });
    this.translateX = newTranslateX;

    element.dataset.newSpeed = newSpeed;
    element.dataset.destinationSpeed = destinationSpeed;

    this.currentSpeed = newSpeed;

    if (!isPaused || newSpeed !== 0) {
      this.nextTick = true;
      requestAnimationFrame(this.tick.bind(this));
    } else {
      this.nextTick = false;
    }
  }

  pause() {
    this.isPaused = true;
  }

  play() {
    this.isPaused = false;
    if (this.nextTick === false) {
      this.tick();
    }
  }
}

export default MObjectsMarqueeRow;
