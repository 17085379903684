import MCalendarEvent from './m-calendar-event';
import MCalendarFreeEntry from './m-calendar-free-entry';

class OCalendar {
  constructor(element) {
    this.element = element;
    this.detailsMenuElements = document.querySelectorAll('.m-calendar-header .a-details-menu details-menu');
    this.filterProperties = ['type', 'audience', 'program', 'location'];
    this.filter = {};

    this.eventElements = element.querySelectorAll('.m-calendar-event');
    this.buttonTogglePassedEventsElement = element.querySelector('button[data-action="show-passed-events"]');

    this.events = Array.from(document.querySelectorAll('.m-calendar-event')).map((eventElement) => new MCalendarEvent(eventElement));
    this.freeEntries = Array.from(document.querySelectorAll('.m-calendar-free-entry'))
      .map((freeEntryElement) => new MCalendarFreeEntry(freeEntryElement));
    this.allEvents = [...this.events, ...this.freeEntries]
      .sort((a, b) => a.startDate >= b.startDate);

    this.passedEvents = this.events.filter((event) => event.isPassed);
    this.upcomingEvents = this.events.filter((event) => !event.isPassed);
    this.passedEventsHidden = false;

    const tooglePassedEvents = () => {
      if (this.buttonTogglePassedEventsElement) {
        const newButtonText = this.buttonTogglePassedEventsElement.dataset.textToggle;
        const oldButtonText = this.buttonTogglePassedEventsElement.innerText;

        this.buttonTogglePassedEventsElement.innerHTML = newButtonText;
        this.buttonTogglePassedEventsElement.dataset.textToggle = oldButtonText;
      }

      if (this.passedEventsHidden === true) {
        this.passedEvents?.forEach((passedEvent) => passedEvent.show());
        this.passedEvents[0]?.updateElement();
      } else {
        this.passedEvents?.forEach((passedEvent) => passedEvent.hide());
      }

      this.upcomingEvents[0]?.updateElement();
      this.passedEventsHidden = !this.passedEventsHidden;

      // extend first upcoming event, when it’s the only upcoming event
      if ((this.upcomingEvents[0] ?? false) && this.upcomingEvents.length === 1) {
        this.upcomingEvents[0].extend();
      }
    };

    const onDetailsMenuSelected = (event) => {
      const { relatedTarget } = event.detail;
      const { parentElement } = relatedTarget;
      const { name } = parentElement?.dataset ?? {};
      const { value } = relatedTarget;
      if (name) {
        if (value) {
          this.filter[name] = value;
        } else {
          delete this.filter[name];
        }
      }
      this.filterElements();
    };

    const onDetailsMenuToggle = (event) => {
      const detailsMenuElement = event.target.querySelector('details-menu');
      const { name } = detailsMenuElement.dataset;
      if (event.target.open) {
        if (Object.keys(this.filter).length === 1 && this.filter[name]) {
          this.resetFilter(detailsMenuElement);
        } else {
          this.updateFilter(detailsMenuElement);
        }
      }
    };

    this.detailsMenuElements.forEach((detailsMenuElement) => {
      detailsMenuElement.addEventListener('details-menu-selected', onDetailsMenuSelected);
      detailsMenuElement.parentElement.addEventListener('toggle', onDetailsMenuToggle);
    });

    // hide passed events, when there are no upcoming and it’s not in educational-offer
    if ((this.passedEvents.length > 0 && this.passedEvents.length !== this.events.length) || this.element.classList.contains('-educational-offer')) {
      tooglePassedEvents();
      this.buttonTogglePassedEventsElement.hidden = false;
      this.buttonTogglePassedEventsElement.addEventListener('click', tooglePassedEvents);
    }
  }

  filterElements() {
    const {
      filter,
      allEvents,
    } = this;
    if (Object.keys(filter).length === 0) {
      allEvents.forEach((event) => {
        event.show();
      });
    } else {
      allEvents.forEach((event) => {
        if (event.constructor.name === 'MCalendarFreeEntry') {
          if (filter.month === event.month) {
            event.show();
          } else {
            event.hide();
          }
        } else {
          let hide = false;

          // eslint-disable-next-line no-restricted-syntax
          for (const property of Object.keys(filter)) {
            if (event[property]?.includes(filter[property])) {
              hide = false;
            } else {
              hide = true;
              break;
            }
          }

          if (hide) {
            event.hide();
          } else {
            event.show();
          }
        }
      });
    }
  }

  resetFilter(detailsMenuElement) {
    const { name } = detailsMenuElement.dataset;
    detailsMenuElement.querySelectorAll('button[value]').forEach((buttonElement) => {
      const { value } = buttonElement;
      const hasVisibleEvent = this.events.find((event) => {
        if (event[name]?.includes(value)) {
          return true;
        }
        return false;
      });
      Object.assign(buttonElement, {
        disabled: !hasVisibleEvent,
      });
    });
  }

  updateFilter(detailsMenuElement) {
    const visibleEvents = this.events.filter((event) => !event.element.hidden);
    const { name } = detailsMenuElement.dataset;
    detailsMenuElement.querySelectorAll('button[value]').forEach((buttonElement) => {
      const { value } = buttonElement;
      const hasVisibleEvent = visibleEvents.find((visibleEvent) => {
        if (visibleEvent[name]?.includes(value)) {
          return true;
        }
        return false;
      });
      Object.assign(buttonElement, {
        disabled: !hasVisibleEvent,
      });
    });
  }
}

document.querySelectorAll('.o-calendar').forEach((element) => new OCalendar(element));
