class OCollectionsThumbs {
  constructor(element) {
    this.element = element;
    if (element.classList.contains('-masonry')) {
      this.liElements = element.querySelectorAll('li');

      window.addEventListener('resize', () => {
        this.updateMasonry();
      }, {
        passive: true,
      });
      window.addEventListener('load', this.updateMasonry.bind(this));
      window.addEventListener('DOMContentLoaded', this.updateMasonry.bind(this));
    }
  }

  updateMasonry() {
    const {
      liElements,
    } = this;
    const { hash } = window.location;

    const activeElement = hash !== '' ? document.querySelector(hash) : null;

    const columns = (() => {
      let column = 1;
      [...liElements].every((liElement) => {
        if (liElement.offsetLeft <= liElement.previousElementSibling?.offsetLeft) {
          return false;
        }
        column += (liElement.classList.contains('-highlight') ? 2 : 1);
        return true;
      });
      return column - 1;
    })();
    this.element.dataset.columns = columns;

    function getElementAbove(liElement) {
      const { row } = liElement;
      const { column } = liElement;
      if (row !== 0) {
        if (liElement.classList.contains('-highlight')) {
          const elementAbove1 = [...liElements].find((_liElement) => _liElement.row === row - 1
            && _liElement.column === column);
          const elementAbove2 = [...liElements].find((_liElement) => _liElement.row === row - 1
            && _liElement.column === column + 1);

          if (parseFloat(elementAbove1?.dataset?.diff) < parseFloat(elementAbove2?.dataset?.diff)) {
            return elementAbove1;
          }
          return elementAbove2;
        }
        let elementAbove = [...liElements].find((_liElement) => _liElement.row === row - 1
          && _liElement.column === column);
        if (!elementAbove) {
          elementAbove = [...liElements].find((_liElement) => _liElement.row === row - 1
            && _liElement.column === column - 1);
        }
        return elementAbove;
      }
      return null;
    }

    const updateMargin = () => {
      liElements.forEach((liElement) => {
        const aElement = liElement.querySelector('a');
        liElement.setAttribute('data-diff', liElement.offsetHeight - aElement.offsetHeight);
        const liElementAbove = getElementAbove(liElement);
        if (liElementAbove) {
          liElement.dataset.prevElement = liElementAbove.id;
          const diff = parseFloat(liElementAbove.dataset?.diff);
          Object.assign(liElement.style, {
            marginTop: `${diff * -1}px`,
          });
        } else {
          Object.assign(liElement.style, {
            marginTop: '0',
          });
        }
      });
      if (activeElement) {
        activeElement.scrollIntoView({
          behavior: 'smooth',
        });
      }
    };

    let index = 0;
    liElements.forEach((liElement) => {
      Object.assign(liElement, {
        row: Math.floor(index / (columns)),
        column: index % (columns),
      });
      Object.assign(liElement.dataset, {
        row: Math.floor(index / (columns)),
        column: index % (columns),
      });
      index += (liElement?.classList.contains('-highlight') ? 2 : 1);
    });

    let n = 0;
    while (n <= liElements.length) {
      n += 1;
      requestAnimationFrame(updateMargin);
    }
  }
}

document.querySelectorAll('.o-collections-thumbs').forEach((element) => new OCollectionsThumbs(element));

export default OCollectionsThumbs;
