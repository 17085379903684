class MCalendarEvent {
  constructor(element) {
    this.element = element;
    this.id = element.id;
    this.buttonElement = element.querySelector('.m-calendar-event__button');
    this.detailsElement = element.querySelector('.m-calendar-event__details');
    this.type = element.dataset.type.split(', ');
    this.audience = element.dataset.audience.split(', ');
    this.program = element.dataset.program.split(', ');
    this.location = element.dataset.location.split(', ');
    this.startDate = new Date(element.dataset.startDate);
    this.endDate = new Date(element.dataset.endDate);
    this.month = element.dataset.month;
    this.isPassed = element.classList.contains('-passed');

    function toggle() {
      if (this.buttonElement.getAttribute('aria-expanded') === 'true') {
        this.close();
      } else {
        this.open();
      }
    }

    this.buttonElement.addEventListener('click', toggle.bind(this));

    if (document.location.hash.substr(1) === this.id) {
      this.open();
      const {
        offsetHeight,
        offsetTop,
      } = this.element;
      const {
        innerHeight,
      } = window;
      if (document.scrollingElement.scrollTo) {
        const marginTop = 100;
        const top = offsetHeight > innerHeight
          ? offsetTop - marginTop
          : offsetTop + offsetHeight * 0.5 - innerHeight * 0.5;
        document.scrollingElement.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }

    this.updateElement();
  }

  updateElement() {
    const {
      element,
      startDate,
    } = this;

    function getPreviousVisibleElementSibling() {
      let siblingElement = element.previousElementSibling;
      while (siblingElement) {
        if (!siblingElement.hidden
          && (siblingElement.classList.contains('m-calendar-event')
          || siblingElement.classList.contains('m-calendar-free-entry'))) {
          return siblingElement;
        }
        siblingElement = siblingElement.previousElementSibling;
      }
      return null;
    }

    function getSameDayFreeEntryElement() {
      let siblingElement = element.previousElementSibling;
      while (siblingElement) {
        const siblingDate = new Date(siblingElement.dataset.startDate);
        if (siblingDate.toDateString() !== startDate.toDateString()) {
          break;
        } else if (siblingElement.classList.contains('m-calendar-free-entry')) {
          return siblingElement;
        }
        siblingElement = siblingElement.previousElementSibling;
      }
      return null;
    }

    const sameDayFreeEntryElement = getSameDayFreeEntryElement();
    if (sameDayFreeEntryElement) {
      sameDayFreeEntryElement.mCalendarFreeEntry?.show();
    }

    const previousVisibleElementSibling = getPreviousVisibleElementSibling();

    // reset
    element.classList.remove('-same-day');
    element.classList.remove('-prev-is-free-entry');
    element.classList.remove('-first-day');

    if (previousVisibleElementSibling) {
      const prevDate = new Date(previousVisibleElementSibling.dataset.startDate);
      if (prevDate.toDateString() === startDate.toDateString()) {
        if (previousVisibleElementSibling.classList.contains('m-calendar-free-entry')) {
          element.classList.add('-prev-is-free-entry');
        }
        element.classList.add('-same-day');
      }
    } else {
      element.classList.add('-first-day');
    }
  }

  extend() {
    const {
      buttonElement,
      detailsElement,
    } = this;
    buttonElement.setAttribute('aria-expanded', 'true');
    detailsElement.hidden = false;
  }

  open() {
    const {
      element,
    } = this;
    this.extend();

    window.history.replaceState(null, '', `${window.location.pathname}${window.location.search}#${element.id}`);
  }

  close() {
    const {
      buttonElement,
      detailsElement,
    } = this;
    buttonElement.setAttribute('aria-expanded', 'false');
    detailsElement.hidden = true;

    if (window.location.hash !== '') {
      window.history.replaceState(null, '', `${window.location.pathname}${window.location.search}`);
    }
  }

  hide() {
    const {
      element,
    } = this;

    element.hidden = true;
  }

  show() {
    const {
      element,
    } = this;

    element.hidden = false;
    this.updateElement();
  }
}

export default MCalendarEvent;
