import Element from './Element';
import Easings from './Easings';

class ODigitalCategory extends Element {
  constructor(element) {
    super(element);
    this.headerElement = element.querySelector('header');
    this.h2Element = element.querySelector('header h2');
    this.element = element;
  }

  setOffsets() {
    super.setOffsets();
    this.offsetHeightHeader = this.headerElement?.offsetHeight;
  }

  onScroll() {
    super.onScroll();
    if (this.isIntersecting) {
      const {
        h2Element,
        headerElement,
        top,
        offsetHeightHeader,
      } = this;

      let relativeYWindow = (top / (offsetHeightHeader * 2)) * -1;
      relativeYWindow = Math.max(Math.min(relativeYWindow, 1), 0);

      if (headerElement && h2Element) {
        headerElement.style.opacity = 1 - 1 * Easings.easeInCubic(relativeYWindow);
        h2Element.style.transform = `translateY(${relativeYWindow * -200}px)`;
      }
    }
  }
}

document.querySelectorAll('.o-digital-category').forEach((element) => new ODigitalCategory(element));
