import MHighlightedExhibitionFigure from './m-highlighted-exhibition__figure';
import Element from './Element';

class MHighlightedExhibition extends Element {
  constructor(element) {
    const rootMargin = '20px 0px';
    super(element, rootMargin);

    const figureElement = element.querySelector('.m-highlighted-exhibition__figure');

    this.element = element;
    this.offsetTopMax = 0;
    this.figure = new MHighlightedExhibitionFigure(figureElement, this);
    this.divElement = element.querySelector('.m-highlighted-exhibition > div');
    this.startAnimationFinished = false;

    if (element.previousElementSibling !== null) {
      this.offsetTopMax = -20;
    }

    setTimeout(() => {
      element.classList.remove('-stage-animation');
    }, 60);
    this.onScroll();
  }

  onIntersect(isIntersecting = true) {
    super.onIntersect(isIntersecting);
    this.figure.onIntersect(isIntersecting);

    const {
      divElement,
      offsetTopMax,
      gap,
      em,
    } = this;

    if (window.matchMedia(`(min-width: ${em(gap * 18)})`).matches) {
      if (divElement && offsetTopMax !== 0) {
        if (isIntersecting) {
          divElement.style.willChange = 'transform';
        } else {
          divElement.style.willChange = null;
        }
      }
    }
  }

  onScroll() {
    super.onScroll();
    const {
      isIntersecting,
      divElement,
      offsetTopMax,
      relativeCenterY,
      gap,
      em,
    } = this;

    if (window.matchMedia(`(min-width: ${em(gap * 18)})`).matches) {
      this.figure?.onScroll();
      if (isIntersecting && divElement && offsetTopMax !== 0) {
        const translateY = relativeCenterY * offsetTopMax * -1;
        divElement.style.transform = `translateY(${translateY}px)`;
      }
    } else if (divElement) {
      divElement.style.transform = 'none';
      this.figure?.reset();
    }
  }
}

document.querySelectorAll('.m-highlighted-exhibition').forEach((element) => new MHighlightedExhibition(element));
