import Easings from './Easings';

class FigureInfo {
  constructor(element, oHouse) {
    const href = `${oHouse.id}/${element.id}`;
    this.hash = `#!${href}`;
    this.element = element;
    this.oHouse = oHouse;
    this.gettingThereButtonCloseElement = element.querySelector('.a-button-icon[data-action="close"]');
    this.textElement = element.querySelector('.m-text');
    this.headerTextButtonElement = oHouse.element.querySelector(`.o-house__header-text a[href="${this.hash}"]`);
    this.trackPageView = () => {
      // eslint-disable-next-line no-underscore-dangle
      const paq = window._paq;
      paq?.push(['setCustomUrl', window.location.href]);
      paq?.push(['trackPageView']);
    };

    const toggleElement = () => {
      if (window.location.hash === this.hash) {
        requestAnimationFrame(() => {
          this.open();
        });
      } else {
        this.close();
      }
    };

    element.addEventListener('keydown', (event) => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.close();
        event.stopPropagation();
      }
    });
    window.addEventListener('hashchange', toggleElement);

    this.headerTextButtonElement.addEventListener('click', (event) => {
      if (window.location.hash === this.hash) {
        this.close();
        event.preventDefault();
      }
    });

    this.gettingThereButtonCloseElement.addEventListener('click', () => {
      this.close();
    });

    toggleElement();
  }

  open() {
    const {
      element,
      headerTextButtonElement,
      textElement,
      oHouse,
    } = this;

    if (element.hidden === true) {
      this.previousActiveElement = document.activeElement;

      element.hidden = false;
      element.focus();
      element.animate([
        {
          opacity: '0',
        },
        {
          opacity: '',
        },
      ], {
        duration: 400,
        easing: Easings.easing(),
      });

      if (textElement?.offsetHeight > oHouse.figureElement.offsetHeight) {
        const currentAnimation = oHouse.figureElement.animate([
          {
            height: `${oHouse.imagesElement?.offsetHeight}px`,
          },
          {
            height: `${textElement?.offsetHeight}px`,
          },
        ], {
          duration: 400,
          easing: Easings.easing(),
        });
        currentAnimation.addEventListener('finish', () => {
          oHouse.figureElement.style.height = `${textElement?.offsetHeight}px`;
        });
      }

      headerTextButtonElement?.classList.add('-active');

      this.trackPageView();
    }
  }

  close() {
    const {
      element,
      headerTextButtonElement,
      oHouse,
    } = this;

    if (element.hidden === false) {
      element.hidden = true;
      if (window.location.hash === this.hash) {
        window.location.hash = '#!';
      }

      if (this.previousActiveElement) {
        this.previousActiveElement.focus();
      }

      const currentAnimation = oHouse.figureElement.animate([
        {
          height: `${oHouse.imagesElement?.offsetHeight}px`,
        },
      ], {
        duration: 400,
        easing: Easings.easing(),
      });
      currentAnimation.addEventListener('finish', () => {
        oHouse.figureElement.style.height = '';
      });
      // oHouse.figureElement.getAnimations().forEach((animation) => {
      //   if (animation !== currentAnimation) {
      //     animation.cancel();
      //   }
      // });

      headerTextButtonElement?.classList.remove('-active');
      this.trackPageView();
    }
  }
}

class OHouse {
  constructor(element) {
    this.element = element;
    this.id = element.id;
    this.figureElement = element.querySelector('.o-house__figure');
    this.imagesElement = element.querySelector('.o-house__images');
    const figureInfoElements = element.querySelectorAll('.o-house__figure-info');

    figureInfoElements.forEach((figureInfoElement) => new FigureInfo(figureInfoElement, this));
  }
}

document.querySelectorAll('.o-house').forEach((element) => new OHouse(element));
