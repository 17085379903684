import MSiteSearch from './m-site-search';

class OMainNav {
  constructor(element) {
    const mSiteSearchElement = document.querySelector('.m-site-search');
    this.buttonElement = element.querySelector('[data-action="toggle"]');
    this.aCurtainElement = document.querySelector('.a-curtain');

    if (mSiteSearchElement) {
      this.siteSearch = new MSiteSearch(mSiteSearchElement);
    }

    this.buttonElement.addEventListener('click', () => {
      if (this.buttonElement.getAttribute('aria-expanded') === 'true') {
        this.close();
      } else {
        this.open();
      }
    });
    this.aCurtainElement.addEventListener('click', () => {
      this.close();
    });
  }

  open() {
    const {
      buttonElement,
      aCurtainElement,
    } = this;
    document.body.classList.add('-nav-open');
    buttonElement.setAttribute('aria-expanded', 'true');
    aCurtainElement.hidden = false;

    if (this.siteSearch) {
      this.siteSearch.formElement.hidden = false;
    }
  }

  close() {
    const {
      buttonElement,
      aCurtainElement,
    } = this;
    document.body.classList.remove('-nav-open');
    buttonElement.setAttribute('aria-expanded', 'false');
    aCurtainElement.hidden = true;
  }
}

const element = document.querySelector('.o-main-nav');
if (element) {
  window.mainNav = new OMainNav(element);
}
