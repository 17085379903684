class MExpandableText {
  constructor(element) {
    const buttonReadOnElement = element.querySelector('button[data-action="expand"]');
    const buttonCloseElement = element.querySelector('button[data-action="shrink"]');
    const textMoreElement = element.querySelector('.m-expandable-text__more');

    buttonReadOnElement.addEventListener('click', () => {
      textMoreElement.hidden = false;
      buttonReadOnElement.hidden = true;
      textMoreElement.focus();
    });

    buttonCloseElement.addEventListener('click', () => {
      textMoreElement.hidden = true;
      buttonReadOnElement.hidden = false;
      buttonReadOnElement.focus();
    });
  }
}

document.querySelectorAll('.m-expandable-text').forEach((element) => new MExpandableText(element));
