// took some functions from https://github.com/github/details-dialog-element/blob/0eb49610d501da67699e532fbda6ca62d00efddf/src/index.ts

import Easings from './Easings';

class OModal {
  constructor(element) {
    const {
      id,
    } = element;
    this.element = element;
    this.divElement = element.querySelector('div');
    this.id = id;
    this.title = element.querySelector('h2, h3')?.innerText || 'title';
    this.originalDocumenTitle = document.title;
    this.trackPageView = () => {
      // eslint-disable-next-line no-underscore-dangle
      const paq = window._paq;
      paq?.push(['setCustomUrl', window.location.href]);
      paq?.push(['setDocumentTitle', document.title]);
      paq?.push(['trackPageView']);
    };

    const aButtonCloseElement = element.querySelector('.a-button-icon[data-action="close"]');

    const toggleElement = () => {
      if (window.location.hash.substr(2) === id) {
        this.open();
      } else {
        this.close();
      }
    };

    function visible(el) {
      return (
        !el.hidden
        && (!el.type || el.type !== 'hidden')
        && (el.offsetWidth > 0 || el.offsetHeight > 0)
      );
    }

    function focusable(el) {
      return el.tabIndex >= 0 && !el.disabled && visible(el);
    }

    function restrictTabBehavior(event) {
      if (!(event.currentTarget instanceof Element)) return;
      event.preventDefault();

      const elements = [...element.querySelectorAll('*')].filter(focusable);
      if (elements.length === 0) return;

      const movement = event.shiftKey ? -1 : 1;
      const root = element.getRootNode();
      const currentFocus = element.contains(root.activeElement) ? root.activeElement : null;
      let targetIndex = movement === -1 ? -1 : 0;

      if (currentFocus instanceof HTMLElement) {
        const currentIndex = elements.indexOf(currentFocus);
        if (currentIndex !== -1) {
          targetIndex = currentIndex + movement;
        }
      }

      if (targetIndex < 0) {
        targetIndex = elements.length - 1;
      } else {
        targetIndex %= elements.length;
      }

      elements[targetIndex].focus();
    }

    aButtonCloseElement.addEventListener('click', () => {
      this.close();
    });
    element.addEventListener('click', (event) => {
      if (event.target === element) {
        this.close();
      }
    });
    element.addEventListener('keydown', (event) => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.close();
        event.stopPropagation();
      } else if (event.key === 'Tab') {
        restrictTabBehavior(event);
      }
    });
    window.addEventListener('hashchange', toggleElement);

    toggleElement();
  }

  open() {
    const {
      element,
      divElement,
    } = this;
    if (element.hidden === true) {
      this.previousActiveElement = document.activeElement;
      document.body.classList.add('-no-scroll');
      element.hidden = false;
      element.animate([
        {
          backdropFilter: 'blur(0)',
          opacity: '0',
        },
        {
          backdropFilter: '',
          opacity: '',
        },
      ], {
        duration: 400,
        easing: Easings.easing(),
      });
      divElement.animate([
        {
          transform: 'translateY(20px)',
          opacity: '0',
        },
        {
          transform: '',
          opacity: '',
        },
      ], {
        duration: 200,
        easing: Easings.easing(),
      });
      element.focus();

      document.title = this.originalDocumenTitle.replace(/(.*) – /, `${this.title} – `);
      this.trackPageView();
    }
  }

  close() {
    const {
      element,
      id,
    } = this;
    if (element.hidden === false) {
      document.body.classList.remove('-no-scroll');
      element.hidden = true;

      if (this.previousActiveElement) {
        this.previousActiveElement.focus();
      }
      if (window.location.hash.substr(2) === id) {
        window.location.hash = '#!';
      }

      document.title = this.originalDocumenTitle;
      this.trackPageView();
    }
  }
}

document.querySelectorAll('.o-modal').forEach((element) => new OModal(element));
