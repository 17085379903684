class MVideo {
  constructor(element) {
    this.cookieBannerElement = element.querySelector('.m-video__cookie-banner');
    this.iframeElement = element.querySelector('iframe');
    const rememberCheckboxElement = element.querySelector('input[name="remember"]');
    const buttonElement = element.querySelector('button');
    const provider = buttonElement.value;

    function showAllVideos() {
      document.mVideos.forEach((mVideo) => {
        mVideo.show();
      });
    }

    if (localStorage.getItem(`${provider}_content`) === 'true') {
      this.show();
    }

    element.addEventListener('submit', (event) => {
      event.preventDefault();
      if (rememberCheckboxElement.checked) {
        localStorage.setItem(`${provider}_content`, 'true');
        showAllVideos();
      }
      this.show();
    });
  }

  show() {
    const {
      iframeElement,
      cookieBannerElement,
    } = this;
    iframeElement.setAttribute('src', iframeElement.dataset.src);
    cookieBannerElement.setAttribute('hidden', '');
    // eslint-disable-next-line no-underscore-dangle
    window._paq?.push(['trackEvent', 'Video', 'Show', iframeElement.dataset.src]);
  }
}

document.mVideos = [];
document.querySelectorAll('.m-video').forEach((element) => {
  document.mVideos.push(new MVideo(element));
});
