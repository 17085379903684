class OCollectionsNav {
  constructor(element) {
    const linkElements = element.querySelectorAll('.a-button-text');
    const collectionElements = document.querySelectorAll('.o-collections-collection');
    this.visibleCollectionElements = [];

    const updateLinkElements = () => {
      linkElements.forEach((linkElement) => {
        const id = linkElement.getAttribute('href').substring(1);
        const visibleElement = this.visibleCollectionElements
          .find((visibleCollectionElement) => visibleCollectionElement.id === id);
        if (visibleElement !== undefined) {
          linkElement.classList.add('-active');
          if (element.scrollTo) {
            element.scrollTo({
              left: linkElement.offsetLeft - 40,
              behavior: 'smooth',
            });
          }
        } else {
          linkElement.classList.remove('-active');
        }
      });
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting === true) {
          this.visibleCollectionElements.push(entry.target);
        } else {
          this.visibleCollectionElements = this.visibleCollectionElements
            .filter((item) => item !== entry.target);
        }
      });
      updateLinkElements();
    }, {
      rootMargin: '-50% 0px -50%',
    });

    collectionElements.forEach((collectionElement) => {
      observer.observe(collectionElement);
    });

    window.addEventListener('wheel', () => {
      if (window.location.hash !== '') {
        window.history.replaceState('', document.title, window.location.pathname + window.location.search);
      }
    });

    updateLinkElements();
  }
}

document.querySelectorAll('.o-collections-nav').forEach((element) => new OCollectionsNav(element));
