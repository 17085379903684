import Easings from './Easings';

class MSiteSearch {
  constructor(element) {
    this.element = element;
    this.bodyElement = document.body;
    this.aCurtainElement = document.querySelector('.a-curtain');
    // this.oMainNavElement = document.querySelector('.o-main-nav');
    this.buttonIconElement = element.querySelector('.m-site-search__icon');
    this.formElement = element.querySelector('.m-site-search__form');
    this.inputElement = this.formElement.querySelector('input[name="search"]');
    this.resultsElement = element.querySelector('.m-site-search__results');
    this.messageElement = element.querySelector('.m-site-search__message');
    this.resultsListElement = this.resultsElement.querySelector('ul');

    this.action = this.formElement.action;
    this.isOpen = false;
    this.isMouseDown = null;
    this.inputTimeout = null;

    this.buttonIconElement.dataset.titleOpen = this.buttonIconElement.title;

    this.buttonIconElement.addEventListener('click', () => {
      if (this.isOpen === false) {
        this.show();
      } else {
        this.hide();
      }
    });

    this.formElement.addEventListener('submit', (event) => {
      event.preventDefault();
      const currentSelectedElement = this.resultsListElement.querySelector('li[aria-selected="true"]');
      if (currentSelectedElement) {
        const href = currentSelectedElement.querySelector('a')?.href;
        window.location = href;
      }
    });
    this.element.addEventListener('mousedown', () => {
      this.isMouseDown = true;
    });
    this.element.addEventListener('mouseup', () => {
      this.isMouseDown = false;
    });
    this.inputElement.addEventListener('blur', () => {
      if (this.isMouseDown === true) {
        this.isMouseDown = null;
      } else if (document.activeElement !== this.inputElement) {
        this.hide();
      }
    });
    this.inputElement.addEventListener('input', () => {
      const query = this.inputElement.value;
      clearTimeout(this.inputTimeout);
      this.inputTimeout = setTimeout(() => {
        this.search(query);
      }, 200);
    });
    this.element.addEventListener('keydown', (event) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        this.selectNext();
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        this.selectPrev();
      }
    });
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        if (this.isOpen === true) {
          this.hide();
          this.buttonIconElement.focus();
        }
      }
    });
  }

  show() {
    const {
      element,
      bodyElement,
      buttonIconElement,
      aCurtainElement,
      formElement,
      inputElement,
    } = this;

    if (document.activeElement !== inputElement) {
      formElement.hidden = false;
      aCurtainElement.hidden = false;
      buttonIconElement.setAttribute('title', buttonIconElement.dataset.titleClose);
      element.classList.add('-open');
      bodyElement.classList.add('-search-open');
      inputElement.classList.add('-no-outline');
      formElement.animate([
        {
          transform: 'translateX(1rem)',
          opacity: 0,
        },
        {
          transform: 'translateX(0)',
          opacity: 1,
        },
      ], {
        duration: 400,
        delay: 200,
        fill: 'backwards',
        easing: Easings.easing(),
      });
      this.aCurtainElement.animate([
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
      ], {
        duration: 600,
        easing: Easings.easing(),
      });

      setTimeout(() => {
        inputElement.focus();
      }, 0);

      this.isOpen = true;
    }
  }

  hide() {
    const {
      element,
      bodyElement,
      buttonIconElement,
      aCurtainElement,
      formElement,
      inputElement,
    } = this;
    if (this.isOpen === true) {
      formElement.hidden = true;
      aCurtainElement.hidden = true;
      buttonIconElement.setAttribute('title', buttonIconElement.dataset.titleOpen);
      element.classList.remove('-open');
      bodyElement.classList.remove('-search-open');
      inputElement.classList.remove('-no-outline');
      if (document.activeElement === inputElement) {
        buttonIconElement.focus();
      }
      // reset
      this.inputElement.removeAttribute('aria-activedescendant');
      this.isOpen = false;
    }
  }

  setFocus(element) {
    if (element) {
      const currentSelectedElements = this.resultsListElement.querySelectorAll('li[aria-selected="true"]');
      currentSelectedElements.forEach((currentSelectedElement) => {
        currentSelectedElement.setAttribute('aria-selected', 'false');
      });
      element.setAttribute('aria-selected', 'true');
      this.inputElement.setAttribute('aria-activedescendant', element.id);
    }
  }

  selectNext() {
    if (this.resultsListElement.childElementCount > 0) {
      const currentSelectedElement = this.resultsListElement.querySelector('li[aria-selected="true"]');
      if (currentSelectedElement?.nextElementSibling) {
        this.setFocus(currentSelectedElement.nextElementSibling);
      }
    }
  }

  selectPrev() {
    if (this.resultsListElement.childElementCount > 0) {
      const currentSelectedElement = this.resultsListElement.querySelector('li[aria-selected="true"]');
      if (currentSelectedElement?.previousElementSibling) {
        this.setFocus(currentSelectedElement.previousElementSibling);
      }
    }
  }

  onMouseEnterListElement(event) {
    const { target } = event;
    const currentSelectedElement = this.resultsListElement.querySelector('li[aria-selected="true"]');
    if (currentSelectedElement && currentSelectedElement !== target) {
      this.setFocus(target);
    }
  }

  resetHtml() {
    this.messageElement.innerHTML = '';
    this.resultsListElement.innerHTML = '';
  }

  async search(query) {
    this.resetHtml();
    if (query.length >= 3) {
      try {
        const response = await fetch(`${this.action}/${encodeURI(query)}`, {
        }).then((_response) => _response.json());

        if (response.status === 'error') {
          this.showException(response.message);
        } else if (response.status === 'info') {
          this.showException(response.message);
        } else if (response.data) {
          this.createResultsList(response.data, query);
        } else {
          this.showException('Fatal Error');
        }
        // eslint-disable-next-line no-underscore-dangle
        window._paq?.push(['trackSiteSearch', query, null, response?.data?.length ?? 0]);
      } catch (error) {
        console.error(error);
        this.showException('Fatal Error');
      }
    }
  }

  showException(message) {
    this.resultsElement.hidden = false;
    this.messageElement.innerText = message;
  }

  createResultsList(data, query) {
    const {
      resultsListElement,
      inputElement,
    } = this;

    const createResultsListItem = (item) => {
      const {
        url,
        title,
        id,
        image,
      } = item;
      const liElement = document.createElement('li');
      let html = `<a href="${url}" tabindex="-1">`;
      if (image !== null) {
        html += `<figure><img src="${image}" alt=""></figure>`;
      }
      html += '<div>';
      html += `<strong>${title}</strong>`;
      html += `<small>${id}</small>`;
      html += '</div>';
      html += '</a>';
      liElement.setAttribute('aria-selected', 'false');
      liElement.setAttribute('role', 'option');
      liElement.setAttribute('id', id);
      liElement.addEventListener('mouseenter', this.onMouseEnterListElement.bind(this));
      liElement.innerHTML = html;
      return liElement;
    };

    // check if query is same as input value
    if (query === inputElement.value) {
      data.forEach((item) => {
        resultsListElement.appendChild(createResultsListItem(item));
      });
      this.resultsElement.hidden = false;

      this.setFocus(resultsListElement.firstChild);
    }
  }
}

export default MSiteSearch;
